<template>
  <!-- 统计管理 -->
  <div class="outer-page">
    <x-table
        :columns="table.columns"
        :config="config"
        :data="table.data"
        :loading="table.loading"
        :no-data-text="CA('statics_check') ? '暂无数据' : '暂无数据查询权限'"
        @add="add"
        @search="search"
        @page-change="pageChange"
        @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
        v-model="modal.show"
        :title="modal.title"
        :width="500"
        @on-visible-change="modalShow"
    >
      <Form ref="form" :label-width="110" :model="form">
        <!-- <FormItem label="统计区域">
          <Input style="width: 300px"></Input>
        </FormItem> -->
        <FormItem label="资源分类">
          <!-- <Input style="width: 300px" v-model="form.resourceTypeName"></Input> -->
          <Cascader
              v-model="form.resourceTypeIds"
              :data="cateList"
              style="width: 300px"
          ></Cascader>
        </FormItem>
        <FormItem label="基地">
          <Select v-model="form.farmInfo" clearable style="width: 300px">
            <Option
                v-for="(item, index) in farmList"
                :key="index"
                :value="item.id + '/' + item.name"
            >{{ item.name }}
            </Option
            >
          </Select>
        </FormItem>
        <FormItem label="选择年份">
          <DatePicker
              v-model="form.year"
              placeholder="请选择年份"
              style="width: 300px"
              type="year"
          ></DatePicker>
        </FormItem>
        <FormItem label="选择月份">
          <DatePicker
              v-model="form.month"
              placeholder="请选择月份"
              style="width: 300px"
              type="month"
          ></DatePicker>
        </FormItem>
        <FormItem label="统计数量">
          <Input
              v-model="form.content"
              placeholder="请输入统计数量"
              style="width: 300px"
          >
            <!-- <span slot="append">
              <Select
                style="width: 50px"
                placeholder=""
                v-model="form.contentUnit"
              >
                <Option
                  v-for="(item, index) in dictList"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</Option
                >
              </Select>
            </span> -->
          </Input>
        </FormItem>
        <FormItem label="资源地址">
          <Input
              v-model="form.fileUrl"
              placeholder="请输入资源地址"
              style="width: 300px"
          ></Input>
        </FormItem>
        <FormItem label="备注">
          <Input
              v-model="form.remark"
              placeholder="备注..."
              style="width: 300px"
              type="textarea"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
import moment from "moment";

export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          //   {
          //     title: "区域",
          //     minWidth: 200,
          //     key: "",
          //   },
          {
            title: "统计类型",
            key: "resourceTypeName",
          },
          {
            title: "统计数量",
            render: (h, {row}) => {
              return <span>{row.content + row.contentUnit}</span>;
            },
          },
          {
            title: "资源地址",
            key: "fileUrl",
          }, {
            title: '基地',
            key: 'farmName',
          },
          {
            title: "创建人",
            key: "createUserName",
          },
          {
            title: "创建时间",
            key: "createTime",
          },
          {
            title: "备注",
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, {row}) => {
              return (
                  <div>
                    {this.CA("statics_update") && (
                        <a
                            style="margin-right: 10px"
                            onClick={() => this.edit(row)}
                        >
                          编辑
                        </a>
                    )}
                    {this.CA("statics_delete") && (
                        <Poptip
                            confirm
                            transfer
                            title="确定删除吗?"
                            on-on-ok={() => this.delete(row.id)}
                        >
                          <a>删除</a>
                        </Poptip>
                    )}
                  </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        resourceTypeId: "",
        resourceTypeIds: [],
        resourceTypeName: "",
        content: "",
        // contentUnit: "",
        fileUrl: "",
        remark: "",
        year: "",
        month: "",
        farmId: "",
        farmName: "",
        farmInfo: "",
      },
      search_data: {},
      //资源分类
      cateList: [],
      //单位列表
      dictList: [],
      //基地列表
      farmList: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增记录",
            ca: "statics_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "统计类型",
              component: "cascader",
              field: "productName",
              defaultValue: [],
              data: this.cateList,
            },
            {
              conditionName: "基地名称",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: this.farmList,
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增记录",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      if (!this.CA("statics_check")) return;
      this.table.loading = true;
      this.table.data = [];
      let resourceTypeId = '';
      if (this.search_data.productName) {
        resourceTypeId = this.search_data.productName.join(',');
      }

      this.$post(this.$api.STATICS_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        resourceTypeId: resourceTypeId,
        ...this.search_data,
      })
          .then((res) => {
            this.table.data = res.list;
            this.page.total = +res.total;
          })
          .finally(() => {
            this.table.loading = false;
          });
    },
    //基地列表
    getFarmList() {
      this.$post(this.$api.FAMRMING.FARMLIST, {siteType: 2}).then((res) => {
        this.farmList = res.list;
        // console.log(res);
      });
    },

    //资源分类
    getcat() {
      this.$post(this.$api.CLASSFICATION.TREE, {
        isAdmin: "1",
      }).then((res) => {
        this.cateList = CU.formatTreeData(res, "id", "name", "children");
      });
    },
    edit(row) {
      for (let key in this.form) {
        if (!row[key]) continue;
        this.form[key] = row[key];
      }
      if (row.farmId && row.farmName)
        this.form.farmInfo = row.farmId + "/" + row.farmName;
      if (row.year && row.month) this.form.month = row.year + "-" + row.month;
      if (row.resourceTypeId)
        this.form.resourceTypeIds = row.resourceTypeId.split(",");
      else this.form.resourceTypeIds = [];
      this.modal = {
        show: true,
        title: "编辑记录",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.STATICS_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    getDict() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "年度统计",
      }).then((res) => {
        this.dictList = res;
      });
    },
    submit() {
      let params = {...this.form};
      params.resourceTypeId = params.resourceTypeIds.toString();
      params.year = moment(this.form.year).format("YYYY");
      params.month = moment(this.form.month).format("MM");
      if (params.farmInfo) {
        params.farmId = this.form.farmInfo.split("/")[0];
        params.farmName = this.form.farmInfo.split("/")[1];
      }
      let url;
      if (params.id) {
        url = this.$api.STATICS_MANAGE.UPDATE;
      } else {
        url = this.$api.STATICS_MANAGE.ADD;
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
            .then(() => {
              this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
              this.getList();
              this.modal.show = false;
            })
            .catch((e) => {
              console.log(e);
            });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        resourceTypeId: "",
        resourceTypeIds: [],
        resourceTypeName: "",
        content: "",
        // contentUnit: "",
        fileUrl: "",
        remark: "",
        year: "",
        month: "",
        farmInfo: "",
        farmId: "",
        farmName: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    this.getcat();
    this.getDict();
    this.getFarmList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>
